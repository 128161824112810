import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../../helpers'
import FaqView from './faq-view'

const FaqController = () => {
  const [loading, setLoading] = useState(false)
  const [faq, setFaq] = useState({})
  const [dataFiltered, setDataFiltered] = useState([])
  const { i18n } = useTranslation()
  const getFaq = async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'claims-support/faq',
      params: {
        locale: i18n.language,
      },
    })
      .then(({ data }) => {
        setFaq(data)
        setDataFiltered(_.get(data, 'content[0]'))
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  const onHandelFilterByGroup = (group) => {
    const dataByFilter = _.head(_.filter(faq.content, { slug: group }))
    setDataFiltered(dataByFilter)
  }

  useEffect(() => {
    getFaq()
  }, [i18n.language])

  const viewProps = {
    data: faq,
    loading,
    onHandelFilterByGroup,
    dataFiltered,
  }
  return <FaqView {...viewProps} />
}

export default FaqController
